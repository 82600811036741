// import { Link } from "react-router-dom";
import "./SamyakFooter.css";
import FinalFooter from "./FinalFooter";
// import logoImg from "./SAMYAK_FaceLogo_white.png";
const SamyakFooter = () => {
  return (
    <>
      <FinalFooter />
    </>
  );
};

export default SamyakFooter;
