import styled from "styled-components";
import HomeHeading from "../UI/HomeHeading";
import Video from "./video.mp4";

const HomeMap = styled.div`
    width: 100%;
    height: auto;
    padding: 20px;

    display: flex;
    justify-content: space-evenly;

    @media only screen and (max-width:1024px) {
        flex-direction: column;
    }
`;

const MapData = styled.div`
    width: 40%;
    iframe {
        width: 100%;
        height: 350px;
        border-radius: 5px;
        height: 320px;
    }
    @media only screen and (max-width:1024px) {
        width: 100%;
        iframe {
            height: 230px;
        }
    }
`;
const MapBox = styled.div`
    padding: 5px 20px;
    width: 45%;
    backdrop-filter: blur(2px);
    background: rgb(0, 0, 0,.2);
    border-top: 2px solid #6ED856;
    border-bottom: 2px solid #6ED856;
    h2 {
        color: #6ED856;
        font-weight: 600;
        letter-spacing: 1px;
    }
    p {
        color: #fff;
        line-height: 25px;
        letter-spacing: .6px;
        margin-top: 20px;  
        text-align: justify;
        text-justify: inter-word; 
    }
    @media only screen and (max-width:1024px) {
        width: 100%;
        padding: 0;
        margin-top: 20px;
        h2 {
            font-size: 21px;
        }
        p {
            font-size: 14px;
            line-height: 24px;
        }
    }
`;

const SamyakAboutKL = () => {
    return (
        <>
            <HomeHeading>ABOUT SAMYAK</HomeHeading>
            <HomeMap>
                <MapData>
                {/* <video autoPlay muted>
        <source
          src={Video}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
                    <iframe title="SamyakLogo" src={Video}  style={{'border': '0'}} autoPlay  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </MapData>
                <MapBox>
                    <h2>Samyak</h2>
                    <p>SAMYAK is a national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy. A 12 year legacy, still burning bright and being passed down to the coming batches is a standing example of the camaraderie the students share even between the passing batches. It shows a great promise in terms of offering a unique experience to students through a plethora of events that strengthen their technical and all-round abilities.</p>
                </MapBox>
            </HomeMap>
            <br></br><br></br>
        </>
    );
}

export default SamyakAboutKL;