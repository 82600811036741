import React, { useEffect, useState } from 'react';
import './Home.css'
import styled from 'styled-components';

const HomeMain = styled.div`

`;

const SectionMain = styled.section`
position: relative;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;

&::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  // background: linear-gradient(to top, var(--primary), transparent);
  z-index: 10;
}
`

const Heading2 = styled.h2`
font-size: 3.5em;
color: #fff;
margin-bottom: 10px;


}
`

const SpanText = styled.span`
font-size: .20em;
    letter-spacing: 2px;
    font-weight: 400;

`

const Image1 = styled.img`
position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
`
const Sec = styled.div`
position: relative;
padding: 60px;
background: var(--primary);
`

const Able = styled.a`
#btn{
  text-decoration: none;
    display: inline-block;
    padding: 8px 30px;
    background: #fff;
    color: var(--primary);
    font-size: 1.2em;
    font-weight: 500;
    letter-spacing: 2px;
    border-radius: 40px;
    transform: translatey(100px);
}
`

function Home() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const value = window.scrollY;
      setScrollY(value);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HomeMain>
      <SectionMain>
       
        <Heading2 id="text" style={{ top: `calc(50% + ${scrollY * -0.1}%)` }}>
          <SpanText>SVAPASYA</SpanText>
          <br />
          Samyak
        </Heading2>

        <Image1
          src="https://user-images.githubusercontent.com/65358991/170092504-132fa547-5ced-40e5-ab64-ded61518fac2.png"
          id="bird1"
          style={{ top: `${scrollY * -1.5}px`, left: `${scrollY * -5}px` }}
        />
        <Image1
          src="https://user-images.githubusercontent.com/65358991/170092542-9747edcc-fb51-4e21-aaf5-a61119393618.png"
          id="bird2"
          style={{ top: `${scrollY * -1.5}px`, left: `${scrollY * 2}px` }}
        />
       
        <Image1
          src="https://user-images.githubusercontent.com/65358991/170092559-883fe071-eb4f-4610-8c8b-a037d061c617.png"
          id="forest"
          style={{ top: `${scrollY * 0.25}px` }}
        />
         <Able href="#" id="btn" style={{ marginTop: `${scrollY * 1.5}px` }}>
          <a href="https://drive.google.com/file/d/1XJg4dF72C-K4Xowvs-bU7dQjRn4woKwr/view?usp=share_link">Invitation</a>
        </Able>
        <Image1
          src="https://user-images.githubusercontent.com/65358991/170092605-eada6510-d556-45cc-b7fa-9e4d1d258d26.png"
          id="rocks"
          style={{ top: `${scrollY * -0.12}px` }}
        />
        
        <Image1
          src="https://user-images.githubusercontent.com/65358991/170092616-5a70c4af-2eed-496f-bde9-b5fcc7142a31.png"
          id="water"
          
        />
      </SectionMain>

      <Sec>
        
      </Sec>

      
     
      </HomeMain>
  );
}

export default Home;

