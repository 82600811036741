import React from 'react';
import styled from 'styled-components';
// import Tilt from 'react-tilt';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  flex-wrap: wrap;
  z-index: 1;
`;

const Card = styled.div`
  position: relative;
  width: 280px;
  height: 400px;
  margin: auto;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
`;

const Content = styled.div`
  padding: 20px;
  text-align: center;
  transform: translateY(25px);
  transition: 0.7s;
  ${Card}:hover & {
    transform: translateY(0px);
  }
`;

const Title = styled.h3`
  font-size: 1.8em;
  color: #fff;
  z-index: 1;
`;

const Text = styled.p`
  font-size: 1em;
  color: #fff;
  font-weight: 300;
`;

// const Link = styled.a`
//   position: relative;
//   display: inline-block;
//   padding: 8px 20px;
//   margin-top: 15px;
//   background: #fff;
//   color: #000;
//   border-radius: 20px;
//   text-decoration: none;
//   font-weight: 500;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
// `;

const CardComponent = () => {
  return (
    <Container>
      {/* <Tilt className="tilt" options={{ max: 25, speed: 400, glare: true, "max-glare": 1 }}> */}
        <Card>
          <Content>
            <Title style={{color: "#6ED856"}}>TECHNICAL EVENTS</Title>
            <Text>
            Technical events are filled with fun, and these
events are designed in a new way for the
participants to participate in these events .
            </Text>
           
          </Content>
        </Card>
        <Card>
          <Content>
            <Title style={{color: "#6ED856"}}>NON TECHNICAL EVENTS</Title>
            <Text>
            Non-technical events are known for the fun the
participants have while participating in these
events.
            </Text>
           
          </Content>
        </Card>
        <Card>
          <Content>
            <Title style={{color: "#6ED856"}}>LITERARY EVENTS</Title>
            <Text>
            These events showcase the brilliance of students
in every regard, by shedding light on the myriad
walks of learning they find their interests firmly
engrossed in.
            </Text>
           
          </Content>
        </Card>
        <Card>
          <Content>
            <Title style={{color: "#6ED856"}}>SPOT EVENTS</Title>
            <Text>
            The power of finding ground in one of the most challenging abilities is confined to not many individuals, which makes the celebration of this glorious gift all the more gratifying.
            </Text>
           
          </Content>
        </Card>     
      {/* </Tilt> */}
    </Container>
  );
};

export default CardComponent;
