import { useContext } from "react";
import styled from "styled-components";
import SamyakFooter from "../BaseComponents/Footer/SamyakFooter";
// import HomeEvents from "./HomeEvents";
// import HomeMusic from "./HomeMusic";
// import HomePoster from "./HomePoster";
import NewHomePoster from "./NewHomePoster";
// import SamyakAbout from "./SamyakAbout";
import SamyakAboutKL from "./SamyakAboutKL";
// import SamyakEvents from "./SamyakEvents";
// import SamyakHomeEvent from "./SamyakHomeEvent";
import SamyakHomeGallery from "./SamyakHomeGallery";
// import SamyakPoster from "./SamyakPoster";
import './Home.css';
// import NewEveningPoster from "./NewEveningPoster";
import CountDown from "./CountDown/CountDown";
// import HomeStalls from "./HomeStalls";
import { ColorContext } from "../../TheRouter";
import EventCards from "../EventCards/eventcards";


const ExtraOptions = styled.div`
  background-color: rgb(40, 40, 43);
`;

const AbsoluteAlignment = styled.div`
  position: absolute;
  top: 100%;
  background-color: #272626;
  // background-image: linear-gradient(${(props) => props.backgroundColor}, rgb(21, 20, 20), rgb(21, 20, 20), rgb(21, 20, 20));
  z-index: 100;
`;

const Home = (props) => {
  var color = useContext(ColorContext);
  color = color.colorObj;
  var backgroundColor = color.backgroundColor;
  var isMobile = window.innerWidth <= 1150 ? true : false;
  return (
    <ExtraOptions>
       {isMobile ? (<NewHomePoster />) : ( <NewHomePoster />)}
      {/* {isMobile ? (<HomePoster name={color.id === 0 ? "mobile1" : "mobile2"} />) : ( <NewHomePoster />)} */}
      {/* {isMobile ? <HomePoster name={color.id === 0 ? "mobile1" : "mobile2"}/> : (color.primaryColor==='#cf9a2e' ? <NewEveningPoster /> : <NewHomePoster />)} */}
      <AbsoluteAlignment backgroundColor={backgroundColor}>
        {/* <SamyakEvents /> */}
        {/* <SamyakAbout /> */}
        {/* <SamyakHomeEvent /> */}
        
        <SamyakAboutKL />

        <CountDown />
        <SamyakHomeGallery />
        <EventCards/>
        <br />
        <br />
        <SamyakFooter />
      </AbsoluteAlignment>
      {/* <HomeEvents />
      <HomeMusic /> */}
      {/* <HomeStalls /> */}
    </ExtraOptions>
  );
};

export default Home;
