const teamData = {
  WEBSITE:[
    {
      "id": "chief1",
      "name": "LALITH KUMAR",
      "designation": "Chief Website",
      "image": "https://i.ibb.co/ZN7ZR0X/1598-2000031030-klunivers.jpg"
    },
    {
      "id": "chief2",
      "name": "Baba Afrid S",
      "designation": "Chief Website",
      "image": "https://i.ibb.co/vqVNbc4/IMG-20231008-144707-609-2100031070-klunivers.jpg"
    },
    
    {
      "id": "core2",
      "name": "Vegulla Venkata Ramana",
      "designation": "Website",
      "image": "https://i.ibb.co/s6Q5KtP/IMG-20221106-WA0006-2200031350-klunivers-1.jpg"
    },
    {//website
      "id": "core1",
      "name": "VSG ABHIRAM",
      "designation": "Website",
      "image": "https://i.ibb.co/Qk93WSs/IMG-20231008-162114-272-2200031532-klunivers.jpg"
    },
    {
      "id": "chief3",
      "name": "Deepak Reddy Gathpa",
      "designation": "Chief Website",
      "image": "https://i.ibb.co/RScjhNP/DP-2100031817-klunivers.jpg"
    },
    {
      "id": "core4",
      "name": "Sai Tadikonda",
      "designation": "Website",
      "image": "https://i.ibb.co/nsVPnB1/Whats-App-Image-2023-10-09-at-10-43-31-AM-2200030805-klunivers.jpg"
    },
    {
      "id": "core5",
      "name": "Sai Harsha",
      "designation": "Website",
      "image": "https://i.ibb.co/QFRfwMb/harsha2dup-2100031411-klunivers.png"
    }, {
      "id": "core3",
      "name": "Dinesh SriSanth Adari",
      "designation": "Website",
      "image": "https://i.ibb.co/mHc474Z/Whats-App-Image-2023-03-05-at-01-24-47-2200031469-klunivers.jpg"
    }
  ],
    CHIEFS: [
      {
        "id": "chief2",
        "name": "G.VINAY",
        "designation": "Chief Executive",
        "image": "https://i.ibb.co/r5NpyRj/2.jpg"
      },
      {
        "id": "chief1",
        "name": "S MAHIDHAR",
        "designation": "Chief Executive",
        "image": "https://i.ibb.co/xz0JsNC/1.jpg"
      },
        {
          "id": "chief5",
          "name": "Charishma Bandla",
          "designation": "Chief Executive",
          "image": "https://i.ibb.co/CwdVjDT/Screenshot-20211118-173938-Gallery-Original-2100560537-klunivers.jpg"
        },
        
        {
          "id": "chief9",
          "name": "G.V.SIVA PRAVEEN",
          "designation": "Chief Secretary",
          "image": "https://i.ibb.co/1z1yHzv/8-3x.jpg"
        },
        {
          "id": "chief9",
          "name": "M.SANDEEP",
          "designation": "Chief Secretary",
          "image": "https://i.ibb.co/cCGFLdx/7.jpg"
        },
        {
          "id": "chief9",
          "name": "Navya Sri Mannava",
          "designation": "Chief Secretary",
          "image": "https://i.ibb.co/6PTxC7z/IMG-20220310-215915-129-2100620056-klunivers.jpg"
        },
        {
          "id": "chief0",
          "name": "Ram Charan",
          "designation": "Chief Student Coordinator",
          "image": "https://i.ibb.co/ZhqqPRz/chief-student-coordinator.jpg"
        },
        {
          "id": "chief6",
          "name": "M.Tanmayi chowdary",
          "designation": "Chief Student Coordinator",
          "image": "https://i.ibb.co/GC7vCV5/IMG-20230923-WA0018-2-2100080084-klunivers.jpg"
        },
        {
          "id": "chief7",
          "name": "Hema Vardhan Velaga",
          "designation": "Chief Student Coordinator",
          "image": "https://i.ibb.co/RvPYPTj/photo-2023-10-08-20-27-06-2100090027-klunivers.jpg"
        },
        {
          "id": "chief8",
          "name": "Amarnadh D",
          "designation": "Chief Student Coordinator",
          "image": "https://i.ibb.co/zb8BPdp/IMG-20231008-234054-2100069001-klunivers.jpg"
        },
        {
          "id": "chief4",
          "name": "M.JASWITHA PRIYA",
          "designation": "Chief Drafting",
          "image": "https://i.ibb.co/MGzcHxh/M-JASWITHA-PRIYA-CHEIF-DRAFTING-2000040055-klunivers.jpg"
        },
        {
          "id": "chief10",
          "name": "Krishna Tej Dama",
          "designation": "Chief Treasurer",
          "image": "https://i.ibb.co/hgKWG0q/IMG-20231008-162221-2100560258-klunivers.jpg"
        },
        {
          "id": "chief11",
          "name": "Asritha Morampudi",
          "designation": "Chief Treasurer",
          "image": "https://i.ibb.co/jhJ2XxJ/IMG-2874-2000040008-klunivers.jpg"
        },
        {
          "id": "chief12",
          "name": "Viresh Adapa",
          "designation": "Chief Treasurer",
          "image": "https://i.ibb.co/0VRmj72/IMG-20230219-WA0024-2100030003-klunivers.jpg"
        },
        {
          "id": "chief13",
          "name": "Parth Thakker",
          "designation": "Chief Technical & Non-Technical Events",
          "image": "https://i.ibb.co/F7syccR/Parth-pic-2010040020-klunivers.png"
        },
        {
          "id": "chief14",
          "name": "Srinivas",
          "designation": "Chief Technical & Non-Technical Events",
          "image": "https://i.ibb.co/Qk5tyd0/IMG-20230614-WA0043-2100070002-klunivers.jpg"
        },
        {
          "id": "chief15",
          "name": "Varshitha",
          "designation": "Chief Technical & Non-Technical Events",
          "image": "https://i.ibb.co/yQw1rxs/varshi-2100031516-klunivers.jpg"
        },
        {
          "id": "chief16",
          "name": "Mohammad Noor",
          "designation": "Chief Technical & Non-Technical Events",
          "image": "https://i.ibb.co/yRjTL65/IMG-1025-2101570019-klunivers.jpg"
        },
        {
          "id": "chief18",
          "name": "Praneeth Reddy",
          "designation": "Chief PR & Marketing",
          "image": "https://i.ibb.co/zbFPYqv/2100560082.jpg"
        },
        {
          "id": "chief19",
          "name": "DHANUSH REDDY ",
          "designation": "Chief PR & Marketing",
          "image": "https://i.ibb.co/y5P45W9/dhanush.jpg"
        },
        {
          "id": "chief20",
          "name": "Rushi Puttigumpala",
          "designation": "Chief PR & Marketing",
          "image": "https://i.ibb.co/CJNg3gX/Rushi-Puttigumpala-2100550017-klunivers.jpg"
        },
        {
          "id": "chief21",
          "name": "Sainanda Katoju",
          "designation": "Chief PR & Marketing",
          "image": "https://i.ibb.co/17PdWVm/Whats-App-Image-2023-10-09-at-08-47-08-74c7cc8-2201510206-klunivers.jpg"
        },
        {
          "id": "chief22",
          "name": "John Rahul",
          "designation": "Chief Sponsorship & Collaboration",
          "image": "https://i.ibb.co/xXXP8Qx/Nerella-JRahulpic-2100030379-klunivers.png"
        },
        // {
        //   "id": "chief23",
        //   "name": "J.V.KALYAN",
        //   "designation": "Chief Sponsorship & Collaboration",
        //   "image": "need"
        // },
        {
          "id": "chief23",
          "name": "Deepak Yelleti",
          "designation": "Chief Logistics ",
          "image": "https://i.ibb.co/XF1KfXp/IMG-20231008-161047-022-2100090151-klunivers.jpg"
        },
        {
          "id": "chief24",
          "name": "Kowshik Reddy",
          "designation": "Chief Logistics ",
          "image": "https://i.ibb.co/XLTn8Zy/Screenshot-20231008-163014-2-2000040182-klunivers.png"
        },
        {
          "id": "chief25",
          "name": "Ganga Pruthveshwar Udtha",
          "designation": "Chief Logistics ",
          "image": "https://i.ibb.co/p6D8j33/Picsart-22-11-19-21-09-59-955-2100520059-klunivers.jpg"
        },
        // {
        //   "id": "chief26",
        //   "name": "Yaswanth Sai",
        //   "designation": "Chief Logistics ",
        //   "image": "https://i.ibb.co/cvxR8bx/IMG-1409-2100630002-klunivers.jpg"
        // },
        {
          "id": "chief27",
          "name": "CH.GAYATHRI",
          "designation": "Chief Logistics ",
          "image": "https://i.ibb.co/FVNkNsr/IMG-20230328-141719-2100590001-klunivers.jpg"
        },
        {
          "id": "chief28",
          "name": "Sk Althaf ",
          "designation": "Chief Hospitality",
          "image": "https://i.ibb.co/hySHSKS/da593a21-72a8-44ac-ac19-ab4b3711fd7f-2000040130-klunivers.jpg"
        },
        {
          "id": "chief24",
          "name": "D.Jyoswitha Sindhu",
          "designation": "Chief-Hospitality",
          "image": "https://i.ibb.co/ZXqJvX2/chief-hospatality.jpg"
        },
        {
          "id": "chief29",
          "name": "LAASYA KOPPAKA",
          "designation": "Chief Hospitality",
          "image": "https://i.ibb.co/pwc8Jv9/samyak-2100030295-klunivers.jpg"
        },
        {
          "id": "chief30",
          "name": "MD ARIF",
          "designation": "Chief Hospitality",
          "image": "https://i.ibb.co/DPJ1CZp/IMG-2332-2201600104-klunivers.jpg"
        },
        {
          "id": "chief31",
          "name": "Thotakura Gayathri",
          "designation": "Chief Registrations and Certifications",
          "image": "https://i.ibb.co/xqPTX3x/IMG-1106-Original-2100030703-klunivers.jpg"
        },
        // {
        //   "id": "chief24",
        //   "name": "G. Pavan Teja",
        //   "designation": "Chief Registrations and Certifications",
        //   "image": "need"
        // },
        
        {
          "id": "chief17",
          "name": "K.Chandu Prakash",
          "designation": "Chief Registrations and Certifications",
          "image": "https://i.ibb.co/Jxj5s3L/Whats-App-Image-2023-10-09-at-5-33-00-PM-2000029004-klunivers.jpg"
        },
        {
          "id": "chief32",
          "name": "Manas",
          "designation": "Chief Registrations and Certifications",
          "image": "https://i.ibb.co/xfmH3vW/IMG-20230217-WA0005-2100010011-klunivers.jpg"
        },
        {
          "id": "chief33",
          "name": "TRINADH PATHURI",
          "designation": "Chief Creative Arts",
          "image": "https://i.ibb.co/6BDtyVG/2100031203-idcard-2100031203-klunivers.jpg"
        },
        {
          "id": "chief34",
          "name": "G N V Surya",
          "designation": "Chief Creative Arts",
          "image": "https://i.ibb.co/t2Xrndg/Screenshot-2023-09-28-21-35-02-57-40deb401b9f-2000040100-klunivers.jpg"
        },
        {
          "id": "chief35",
          "name": "NAGARAM DEEPTHI ",
          "designation": "Chief Creative Arts",
          "image": "https://i.ibb.co/3hNPZzj/IMG-20231008-WA0086-2100560533-klunivers.jpg"
        },
        {
          "id": "chief36",
          "name": "Nilesh Samanta",
          "designation": "Chief Creative Arts",
          "image": "https://i.ibb.co/Kr6811K/IMG-20231009-WA0006-2200540022-klunivers.jpg"
        },
        {
          "id": "cheif1",
          "name": "Vinesh",
          "designation": "Chief Broadcasting ",
          "image": "https://i.ibb.co/18vpvcN/chief-broadcasting.jpg"
        },
        {
          "id": "chief37",
          "name": "Harsha vardhan",
          "designation": "Chief Broadcasting ",
          "image": "https://i.ibb.co/0qYhRc8/Picsart-23-02-14-20-02-43-292-2100090083-klunivers.jpg"
        },
        {
          "id": "chief38",
          "name": "RISHI KIRAN",
          "designation": "Chief Broadcasting ",
          "image": "https://i.ibb.co/L1hqqrK/30-colour-2000040015-klunivers.jpg"
        },
        {
          "id": "chief39",
          "name": "G.Deepak",
          "designation": "Chief Broadcasting ",
          "image": "https://i.ibb.co/XsQhJ4P/IMG-20230719-213459-2-2200540006-klunivers.jpg"
        },
        {
          "id": "chief40",
          "name": "M.Amish kumar",
          "designation": "Chief Broadcasting ",
          "image": "https://i.ibb.co/CVLPWbZ/1696819821718-2100030718-klunivers.jpg"
        },
        {
          "id": "chief24",
          "name": "Lalith Kammili",
          "designation": "Chief Designing and VFX",
          "image": "https://i.ibb.co/9NsQV2B/chief-designing-and-vfx-min.jpg"
        },
        {
          "id": "chief24",
          "name": "P.Ganesh",
          "designation": "Chief Designing",
          "image": "https://i.ibb.co/FhtMG4X/chief-desinging.jpg"
        },
    
        {
          "id": "chief43",
          "name": "D JASWANTH",
          "designation": "Chief Designing",
          "image": "https://i.ibb.co/JRYPwwF/20221218053108-IMG-6650-2100030119-klunivers.jpg"
        },
        {
          "id": "chief44",
          "name": "LOKESH NAVULURI",
          "designation": "Chief Stage Management & Culturals ",
          "image": "https://i.ibb.co/DwdMWXT/IMG-5788-2100030374-klunivers.jpg"
        },
        {
          "id": "chief45",
          "name": "SAI",
          "designation": "Chief Stage Management & Culturals ",
          "image": "https://i.ibb.co/b3bbnGS/IMG-20220810-225542-873-2100030222-klunivers.jpg"
        },
        {
          "id": "chief46",
          "name": "Venkat Tanish",
          "designation": "Chief Stage Management & Culturals ",
          "image": "https://i.ibb.co/MRfMb9n/IMG-0212-2200030900-klunivers.jpg"
        },
        {
          "id": "chief47",
          "name": "Yaswanth giri ",
          "designation": "Chief Stage Management & Culturals ",
          "image": "https://i.ibb.co/QmMtXMW/IMG-20210829-WA0033-2000040101-klunivers.jpg"
        },
        {
          "id": "chief48",
          "name": "Chaitanya",
          "designation": "Chief Info, Communication & HR",
          "image": "https://i.ibb.co/PMyq5sh/Screenshot-2023-10-09-03-21-44-56-1c337646f29-2200520034-klunivers.jpg"
        },
        {
          "id": "chief24",
          "name": "BL GNAPIKAA",
          "designation": "Chief Info, Communication & HR",
          "image": "https://i.ibb.co/tc0QV9j/chief-hr-and-info.jpg"
        },
        {
          "id": "chief49",
          "name": "KARTHIKAY ATTULURI ",
          "designation": "Chief Department Coordinator ",
          "image": "https://i.ibb.co/YW3bwSW/Screenshot-2023-10-08-16-11-26-67-6012fa4d4dd-2000070014-klunivers.jpg"
        },
        {
          "id": "chief50",
          "name": "Varun Krishna ",
          "designation": "Chief Department Coordinator ",
          "image": "https://i.ibb.co/qNYdKB7/29-2000040080-klunivers.jpg"
        },
        {
          "id": "chief51",
          "name": "ASISH NOEL",
          "designation": "Chief Department Coordinator ",
          "image": "https://i.ibb.co/T4tCKWr/IMG-20231008-182735-760-2100031244-klunivers.jpg"
        }
      ]
      ,
      CORES: [

       
        {//Technical Events
          "id": "core6",
          "name": "Rasagna Devi K",
          "designation": "Technical Events",
          "image": "https://i.ibb.co/z7Cm5dN/IMG-20231007-230603-577-2100090066-klunivers.jpg"
        },
        {
          "id": "core5",
          "name": "G. Uday Sai Krishna ",
          "designation": "Technical Events",
          "image": "https://i.ibb.co/7CNtLtV/20230106-115010-2100020003-klunivers.jpg"
        },
        {
          "id": "core7",
          "name": "G V Varun Chand",
          "designation": "Technical Events",
          "image": "https://i.ibb.co/mFv9wxk/Varun-2100030158-klunivers.jpg"
        },
       
        {
          "id": "core9",
          "name": "Harika Yanamadala ",
          "designation": "Technical Events",
          "image": "https://i.ibb.co/R2c6g58/Screenshot-2023-10-09-01-18-43-437-com-google-2100030438-klunivers.jpg"
        },
        
        {
          "id": "core11",
          "name": "Gopi Prashanth ",
          "designation": "Technical Events",
          "image": "https://i.ibb.co/dmphT1T/IMG-20231010-WA0020-2100020007-klunivers.jpg"
        },
        {//Non-Technical Events
          "id": "core12",
          "name": "Manish",
          "designation": "Non-Technical Events",
          "image": "https://i.ibb.co/N9b3z3H/DSC-001-2100030199-klunivers.jpg"
        },
        {
          "id": "core4",
          "name": "SHAIK RABBANI ",
          "designation": "Non-Technical Events",
          "image": "https://i.ibb.co/XbnNc8L/31-colour-2000040128-klunivers.jpg"
        },
        {
          "id": "core13",
          "name": "Pravallika",
          "designation": "Non-Technical Events",
          "image": "https://i.ibb.co/hHCJ0JX/IMG-20231006-180058-2100620002-klunivers.jpg"
        },
        {
          "id": "core14",
          "name": "MIDHUN SAI KOTHAGUNDLA",
          "designation": "Non-Technical Events",
          "image": "https://i.ibb.co/sPtB10F/IMG-20230116-224449-2100090016-klunivers.jpg"
        },
        {
          "id": "core16",
          "name": "P Poorna",
          "designation": "Non-Technical Events",
          "image": "https://i.ibb.co/X8d1jZq/IMG-8818-2100031999-klunivers.jpg"
        },
        {
          "id": "core17",
          "name": "M. Karthikeya",
          "designation": "Non-Technical Events",
          "image": "https://i.ibb.co/zxcgfBM/IMG-20221011-222528-389-2100530058-klunivers.jpg"
        },
        {//Sponsorship & Collaborations
          "id": "core18",
          "name": "Dev",
          "designation": "Sponsorship & Collaborations",
          "image": "https://i.ibb.co/QXjxRtn/3fb1a471-ce9d-4049-8208-e1b088e6f77e-2200090213-klunivers.jpg"
        },
        {
          "id": "core19",
          "name": "Preethi Rathore",
          "designation": "Sponsorship & Collaborations",
          "image": "https://i.ibb.co/sWrgRsk/IMG-20230402-WA0028-2100560517-klunivers.jpg"
        },
        // {
        //   "id": "core20",
        //   "name": "Ududala Ashok",
        //   "designation": "Sponsorship & Collaborations",
        //   "image": "https://i.ibb.co/zWM6268/IMG-20221105-163659-2100590016-klunivers.jpg"
        // },
        {//Registrations & Certification
          "id": "core21",
          "name": "Samuel",
          "designation": "Registrations & Certification",
          "image": "https://i.ibb.co/pRF7NZR/IMG-20230327-230954-502-2200530001-klunivers-1.jpg"
        },
        {
          "id": "core22",
          "name": "MOHAMMED MISHAL",
          "designation": "Registrations & Certification",
          "image": "https://i.ibb.co/L0GRbMV/IMG-6168-2100069037-klunivers.jpg"
        },
        {
          "id": "core23",
          "name": "MADHURI NALLAM",
          "designation": "Registrations & Certification",
          "image": "https://i.ibb.co/QCh59Pz/madhuri.jpg"
        },
        {
          "id": "core24",
          "name": "M . Mohan Sai",
          "designation": "Registrations & Certification",
          "image": "https://i.ibb.co/0CB673w/IMG-20230320-WA0002-2-2100620182-klunivers.jpg"
        },
        // {
        //   "id": "core25",
        //   "name": "THIMMISETTY SAMPATH KUMAR",
        //   "designation": "Registrations & Certification",
        //   "image": "https://i.ibb.co/3kjZZkn/photo-2022-08-05-10-41-36-2100039026-klunivers.jpg"
        // },
        // {
        //   "id": "core26",
        //   "name": "EDE GNAPIKA SAI",
        //   "designation": "Registrations & Certification",
        //   "image": "https://i.ibb.co/kysWqjj/pasport-size-photo-2100039003-klunivers-1.jpg"
        // },
        {
          "id": "core28",
          "name": "TEJA SRI.CHAPARALA",
          "designation": "Registrations & Certification",
          "image": "https://i.ibb.co/3FnwNCv/pic-2000049045-klunivers.jpg"
        },
        // {
        //   "id": "core29",
        //   "name": "Tej Thota",
        //   "designation": "Registrations & Certification",
        //   "image": "https://i.ibb.co/SyvrnGr/IMG-3256-2200032623-klunivers.jpg"
        // },
        {//Logistics and Space Management
          "id": "core30",
          "name": "Yaswanth",
          "designation": "Logistics and Space Management",
          "image": "https://i.ibb.co/NpPVqpz/IMG-1409-2100630002-klunivers.jpg"
        },
        {
          "id": "chief24",
          "name": "Sujith Reddy",
          "designation": "Logistics and Space Management",
          "image": "https://i.ibb.co/njy6pTx/chief-logistics-min.jpg"
        },
        {
          "id": "core31",
          "name": "Surya Sanjay ",
          "designation": "Logistics and Space Management",
          "image": "https://i.ibb.co/NpbWg4S/IMG-20231010-120857-2100520200-klunivers.jpg"
        },
        {
          "id": "core32",
          "name": "Vurity Aishwarya",
          "designation": "Logistics and Space Management",
          "image": "https://i.ibb.co/Yhv8L1H/1696928962702-2200520137-klunivers.jpg"
        },
        {
          "id": "core33",
          "name": "S Sasidhar",
          "designation": "Logistics and Space Management",
          "image": "https://i.ibb.co/64tPprk/66-2200030526-klunivers.jpg"
        },
        {//Public Relations & Marketing
          "id": "core34",
          "name": "A. SAI BHAVYA REDDY",
          "designation": "Public Relations & Marketing",
          "image": "https://i.ibb.co/7y37Vkq/Screenshot-2023-10-08-16-35-35-234-com-sandis-2200031603-klunivers.jpg"
        },
        {
          "id": "core35",
          "name": "Aditya",
          "designation": "Public Relations & Marketing",
          "image": "https://i.ibb.co/d6WH2ZJ/IMG-20231008-232659-2200032545-klunivers.jpg"
        },
        {
          "id": "core36",
          "name": "Teju",
          "designation": "Public Relations & Marketing",
          "image": "https://i.ibb.co/ZTJGq5N/IMG-20231006-WA0004-2100031177-klunivers.jpg"
        },
        {
          "id": "core37",
          "name": "Karishma.shaik",
          "designation": "Public Relations & Marketing",
          "image": "https://i.ibb.co/ckysz6p/DSC-0040-2100560324-klunivers.jpg"
        },
        {
          "id": "core38",
          "name": "Johanna Lam",
          "designation": "Public Relations & Marketing",
          "image": "https://i.ibb.co/yXc5bc6/IMG-3677-2201510134-klunivers.jpg"
        },
        {//Hospitality
          "id": "core39",
          "name": "T SOHAN",
          "designation": "Hospitality",
          "image": "https://i.ibb.co/TqHrj1v/2100030507-2100030507-klunivers.jpg"
        },
        {
          "id": "core40",
          "name": "TARUN REDDY",
          "designation": "Hospitality",
          "image": "https://i.ibb.co/vxJsxfL/IMG-20231008-211819-574-2100630005-klunivers.jpg"
        },
        {
          "id": "core41",
          "name": "G.Sri Veditha",
          "designation": "Hospitality",
          "image": " https://i.ibb.co/0Mnq3X0/Snapchat-40063383-2100032182-klunivers.jpg"
        },
        // {
        //   "id": "core42",
        //   "name": "Harsha Vardhan",
        //   "designation": "Hospitality",
        //   "image": "https://i.ibb.co/b5y6PYS/20220417133517-IMG-3149-2201600041-klunivers.jpg"
        // },
        // {
        //   "id": "core43",
        //   "name": "Ram",
        //   "designation": "Hospitality",
        //   "image": "https://i.ibb.co/47cSWt0/IMG-20231010-WA0001-2201600113-klunivers.jpg"
        // },
        // {//Broadcasting
        //   "id": "core44",
        //   "name": "M. Hari Kiran",
        //   "designation": "Broadcasting",
        //   "image": "https://i.ibb.co/YpWB99L/1696765085154-2100090020-klunivers.jpg"
        // },
        {
          "id": "core45",
          "name": "M. Udaykiran",
          "designation": "Broadcasting",
          "image": "https://i.ibb.co/3d3tN67/IMG-20231008-WA0055-2200030316-klunivers.jpg"
        },
        {
          "id": "core46",
          "name": "HARIHARAN",
          "designation": "Broadcasting",
          "image": "https://i.ibb.co/L0ZhjKR/IMG-20220917-203401-087-2200040063-klunivers.jpg"
        },
        {
          "id": "core47",
          "name": "Himanth",
          "designation": "Broadcasting",
          "image": "https://i.ibb.co/nfvJgGc/IMG-7002-2200032412-klunivers.jpg"
        },
        {
          "id": "core48",
          "name": "Gnanesh",
          "designation": "Broadcasting",
          "image": "https://i.ibb.co/MZns0ZF/IMG-7724-2100560026-klunivers.jpg"
        },
        {//Designing & VFX
          "id": "core49",
          "name": "CHINNI KNS YAGNESHWAR",
          "designation": "Designing & VFX",
          "image": "https://i.ibb.co/1Q9S47z/yagneshwar-chinni-2200032877-2200032877-klunivers.jpg"
        },
        {
          "id": "core50",
          "name": "Dhruv Kohli",
          "designation": "Designing & VFX",
          "image": "https://i.ibb.co/8g5yn0C/IMG-20231009-111818-2200033125-klunivers.jpg"
        },
        {
          "id": "core51",
          "name": "Devu Govardhan",
          "designation": "Designing & VFX",
          "image": "https://i.ibb.co/YZBKWh7/E603-F842-C317-4460-B328-CBCC0-EDEA43-B-Original-2100030127-klunivers.jpg"
        },
        {//Drafting
          "id": "core52",
          "name": "M.Sri Namratha",
          "designation": "Drafting",
          "image": "https://i.ibb.co/4p6bCN9/IMG-20231008-163003-2100099002-klunivers.jpg"
        },
        {
          "id": "core53",
          "name": "T R Mahathi",
          "designation": "Drafting",
          "image": "https://i.ibb.co/gF2gx0h/Snapchat-1423033082-2200030333-klunivers.jpg"
        },
        {
          "id": "core54",
          "name": "Tummala Hruday",
          "designation": "Drafting",
          "image": "https://i.ibb.co/PM7t04b/13-colour-2000040148-klunivers.jpg"
        },
        {
          "id": "core55",
          "name": "Krishna vamsi",
          "designation": "Drafting",
          "image": "https://i.ibb.co/dMdnRCf/IMG-2253-2100030171-klunivers.jpg"
        },
        {
          "id": "core56",
          "name": "Manu",
          "designation": "Drafting",
          "image": "https://i.ibb.co/8zGrtnr/Samyak-pic-2100560017-klunivers.jpg"
        },
        {
          "id": "core57",
          "name": "Ch.sai prakash",
          "designation": "Stage Management & Stalls",
          "image": "https://i.ibb.co/h7B1Cm1/core-stage-management-and-stalls.jpg"
        },
        // {
        //   "id": "core57",
        //   "name": "Manish Desireddi",
        //   "designation": "Stage Management & Stalls",
        //   "image": "https://i.ibb.co/gTqL9wN/IMG-20230505-105951-2200080222-klunivers.jpg"
        // },
        // {
        //   "id": "core58",
        //   "name": "NANI CHALLA",
        //   "designation": "Stage Management & Stalls",
        //   "image": "https://i.ibb.co/NxW8WzK/IMG-20231008-161751-2100620089-klunivers.jpgb"
        // },
        {
          "id": "core76",
          "name": "MAHADEV KOLAGANI",
          "designation": "Stage Management & Stalls",
          "image": "https://i.ibb.co/c1szCWT/1-1-2-2200540003-klunivers.jpg"
        },
        {
          "id": "core59",
          "name": "M.Vijaya sri varsha",
          "designation": "Stage Management & Stalls",
          "image": "https://i.ibb.co/pxBpPYR/IMG-20221025-WA0031-2100090068-klunivers.jpg"
        },
        {
          "id": "core60",
          "name": "Anjali Suchitra",
          "designation": "Stage Management & Stalls",
          "image": "https://i.ibb.co/s2DVJqp/IMG-20230507-WA0007-2100560174-klunivers.jpg"
        },
        {
          "id": "core61",
          "name": "Bhuvana",
          "designation": "Stage Management & Stalls",
          "image": "https://i.ibb.co/Z6w8Grb/me-2100030364-klunivers.jpg"
        },
        {
          "id": "core62",
          "name": "R Karthik Chowdary",
          "designation": "Stage Management & Stalls",
          "image": "https://i.ibb.co/Vwj4k9Q/DSC-5781-2-2100040134-klunivers.jpg"
        },
        {//Info & Communication
          "id": "core63",
          "name": "GODAVARTHI SUBRAMANYA MURALI KOWSIK",
          "designation": "Info & Communication",
          "image": "https://i.ibb.co/vhY1Zbs/IMG-20230823-075547-071-2100030169-klunivers.jpg"
        },
        {
          "id": "core64",
          "name": "Ziya Afreen",
          "designation": "Info & Communication",
          "image": "https://i.ibb.co/khCR9qT/Ziyq-Afreen-jpeg-2100090133-klunivers.jpg"
        },
        // {
        //   "id": "core65",
        //   "name": "ManikantaSai Maturi",
        //   "designation": "Info & Communication",
        //   "image": "https://i.ibb.co/pxkRZDS/20231008-182137-2100630009-klunivers.jpg"
        // },
        {//Culturals
          "id": "core66",
          "name": "M.SAI BHAVANA",
          "designation": "Culturals",
          "image": "https://i.ibb.co/ncgCfMP/photo1696775797-2000040088-klunivers.jpg"
        },
        {
          "id": "core8",
          "name": "T. MIHIR KUMAR",
          "designation": "Culturals",
          "image": "https://i.ibb.co/g9Jm3HX/IMG-20230815-091830-2100030526-klunivers.jpg"
        },
        {
          "id": "core67",
          "name": "Pavan Karthik",
          "designation": "Culturals",
          "image": "https://i.ibb.co/rMqNWyC/Photo-For-ID-2200031219-klunivers.jpg"
        },
        {
          "id": "core68",
          "name": "Praharshini",
          "designation": "Culturals",
          "image": "https://i.ibb.co/mNF5T39/IMG-20231009-WA0004-2100032062-klunivers.jpg"
        },
        {
          "id": "core69",
          "name": "Dubaguntla Sandeep",
          "designation": "Culturals",
          "image": "https://i.ibb.co/51Xq6rD/20220314-173313-01-2100030897-klunivers.jpg"
        },
        {//HR
          "id": "core70",
          "name": "Y DEEPIKA JYOTHI",
          "designation": "HR",
          "image": "https://i.ibb.co/GnmSV0q/Y-DEEPIKA-JYOTHI-2000040164-klunivers.jpg"
        },
        {
          "id": "core71",
          "name": "SILLA GANESH",
          "designation": "HR",
          "image": "https://i.ibb.co/k3qVM4N/GP-2200030622-klunivers.jpg"
        },
        {
          "id": "core72",
          "name": "Vivek reddy",
          "designation": "HR",
          "image": "https://i.ibb.co/JmYyRDp/AE48-ED37-0-F5-B-4-DF4-953-A-049-C731-EC52-D-2200030038-klunivers.jpg"
        },

        {
          "id": "core75",
          "name": "Varshitha Madasi",
          "designation": "HR",
          "image": "https://i.ibb.co/gygC4bd/Crazybish-2100032075-klunivers.jpg"
        },
        {
          "id": "core73",
          "name": "Medha Naavya",
          "designation": "HR",
          "image": "https://i.ibb.co/p4WHJWq/20230909-191333-2200031218-klunivers.jpg"
        },
        {//Creative Arts
          "id": "core74",
          "name": "Satya Venkat Munagala",
          "designation": "Creative Arts",
          "image": "https://i.ibb.co/Bg2qVC8/IMG-20221120-WA0031-2200030975-klunivers.jpg"
        },
        {
          "id": "core77",
          "name": "ROLLA ANIL KUMAR",
          "designation": "Creative Arts",
          "image": "https://i.ibb.co/7vV6bXh/IMG-20220703-000449-2100030465-klunivers.jpg"
        },
        {
          "id": "core78",
          "name": "JAWAAD",
          "designation": "Creative Arts",
          "image": "https://i.ibb.co/H253m6g/Adobe-Express-20231008-2056270-1-2200033275-klunivers.png"
        },
        {
          "id": "core79",
          "name": "Ishitha Agnes Marripudi",
          "designation": "Creative Arts",
          "image": "https://i.ibb.co/Y8Xz2D7/2-S1-A3947-2200031419-klunivers.jpg"
        },
        {
          "id": "core80",
          "name": "Nagamani Thota",
          "designation": "Creative Arts",
          "image": "https://i.ibb.co/Wg8xVmL/IMG-3922-2000040298-klunivers.jpg"
        },
        // {
        //   "id": "core82",
        //   "name": "Rishika Reddy",
        //   "designation": "Creative Arts",
        //   "image": "https://i.ibb.co/hBqs5bx/IMG-20230918-155515-2200033131-klunivers.jpg"
        // },
        {
          "id": "core83",
          "name": "Shoaib Syed",
          "designation": "Creative Arts",
          "image": "https://i.ibb.co/C5C1L46/IMG-20230930-WA0008-2100030704-klunivers.jpg"
        }
      ]
};

export default teamData;