import styled from "styled-components";
import SamyakFooter from "../BaseComponents/Footer/SamyakFooter";
import NavBarSpace from "../BaseComponents/NavBarSpace";
import HomeHeading from '../UI/HomeHeading';
import HomeNavigateButton from "../UI/HomeNavigateButton";

import image2 from './img/image2.png';
import image3 from './img/image3.png';
import image4 from './img/image4.jpg';

const MainContainer = styled.div`
    position: relative;
    height: auto;
    background-color: #272626;
    width: 100%;
`;

const AboutSamyak = styled.div`
    height: auto;
    display: flex;
    justify-content: space-evenly;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;
const AboutSamyakLeft = styled.div`
    padding: 20px;
    h1 {
        color: #6ed856;
        font-weight: 600;
        letter-spacing: 1px;
    }
    p {
        color: #ffffffd8;
        margin-top: 25px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: .5px;
    }

    width: 45%;
    @media only screen and (max-width: 1024px) {
        width: 100%;
        p {
            font-size: 14px;
        }
        h1 {
            font-size: 22px;
        }
    }
`;
// const AboutLocation = styled.div`
//     margin-top: 30px;
//     h3 {
//         color: #ffb521;
//         font-weight: 500;
//         font-size: 23px;
//     }
//     p {
//         margin-top: 10px;
//         color: #b6b6b6;
//     }
//     span {
//         width: 50%;
//     }

//     display: flex;
//     justify-content: space-between;
//     @media only screen and (max-width: 1024px) {
//         flex-direction: column;
//         h2 {
//             font-size: 14px;
//         }
//         h3 {
//             font-size: 17px;
//         }
//         span {
//             width: 100%;
//             text-align: center;
//         }
//     }
// `;
const AboutSamyakRight = styled.div`
    padding: 20px;
    flex-wrap: wrap;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;

    img {
        width: 70%;
        border-radius: 7px;
        border: 2px solid #6ed856;
    }
    @media only screen and (max-width: 1024px) {
        width: 100%;
        img {
            width: 90%;
            height: auto;
            margin-top: 20px;
        }
    }
`;

const AboutTheme = styled.div`
    margin: 4pc 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;
const AboutThemeLeft = styled.div`
    padding: 20px;
    height: auto;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 400px;
        border-radius: 6px;
        border: 2px solid #6ed856;
    }
    @media only screen and (max-width: 1024px) {
        width: 100%;
        p {
            font-size: 14px;
        }
        img {
            width: 90%;
            height: auto;
            margin-top: 20px;
        }
    }
`;
const AboutThemeRight = styled.div`
    padding: 20px;
    height: auto;
    width: 45%;
    h1 {
        color: #6ed856;
        font-weight: 600;
        letter-spacing: 1px;
    }
    p {
        color: #ffffffd8;
        margin-top: 25px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: .5px;
    }
    @media only screen and (max-width: 1024px) {
        width: 100%;
        h1 {
            font-size: 22px;
        }
    }
`;

const AboutKLU = styled.div`
    height: auto;
    margin: auto;
    display: flex;
    justify-content: space-evenly;

    h1 {
        color: #6ed856;
        font-weight: 600;
        letter-spacing: 1px;
    }
    p {
        color: #ffffffd8;
        margin-top: 25px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: .5px;
    }
    span {
        margin-top: 25px;
    }
    span img {
        width: 50%;
        height: auto;
        border-radius: 5px;
    }
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        h1 {
            font-size: 22px;
        }
    }
`;
const AboutKLULeft = styled.div`
    padding: 20px;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
        border: 2px solid #6ed856;
    }
    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
`;
const AboutKLURight = styled.div`
    padding: 20px;
    width: 45%;
    a {
        position: relative;
        top: 15px;
        color: #6ed856;
        text-decoration: none;
    }
    @media only screen and (max-width: 1024px) {
        width: 100%;
        p {
            font-size: 14px;
        }
    }
`;

const SamyakAbout = () => {
    return(
        <>
            <MainContainer>
                <NavBarSpace /><br></br><br></br>
                <HomeHeading>ABOUT</HomeHeading>
                <AboutSamyak>
                    <AboutSamyakLeft>
                    <h1>About Samyak</h1>
                        <p>SAMYAK is a national-level techno-management fest organized by 
                            the students of KL University. It is a two- day festival filled 
                            with activities, events, workshops, and student performances that 
                            drive the students to experience the joy in being part of a synergy.</p>
                            {/* <MoreEvents>
                                <p onclick="About()" id="aboutButton">Read More</p>
                            </MoreEvents> */}
                        <p id="about">The colours popping throughout, the theme-relevant artworks 
                            designed from scratch by the student teams, the elegance in 
                            student groups coming together after all the effort they put 
                            in until the day of the fest cannot be looked past.</p>
                        <p id="about1">A 12 year legacy, still burning bright and being passed down 
                            to the coming batches is a standing example of the camaraderie 
                            the students share even between the passing batches. It shows a 
                            great promise in terms of offering a unique experience to students 
                            through a plethora of events that strengthen their technical and 
                            all-round abilities.</p>
                            {/* <AboutLocation>
                                <span class="col-50">
                                    <h3>Where</h3>
                                    <p>KL University, Vijayawada</p>
                                </span>
                                <span class="col-50">
                                    <h3>When</h3>
                                    <p>OCT 20 - OCT 21</p>
                                </span>
                            </AboutLocation><br></br> */}
                            <HomeNavigateButton route="/register">REGISTER</HomeNavigateButton>
                    </AboutSamyakLeft>
                    <AboutSamyakRight>
                    <img src={image3} alt="3"/>
                    </AboutSamyakRight>
                </AboutSamyak>
                <AboutTheme>
                    <AboutThemeLeft>
                        <img src={image2} alt="3"/>
                    </AboutThemeLeft>
                    <AboutThemeRight>
                        <h1>About SVAPASYA</h1>
                        <p><b>svapasyā स्वपस्या</b></p>
                        <p>A skillful path towards sustainable development. Skill is the foundation upon which we build our aspirations and ambitions. Skills are the building blocks of personal and societal progress. They empower individuals to reach their potential, contribute to their communities, and adapt to the ever-changing demands of the world. The importance of skill in life cannot be overstated, as it influences our capacity to succeed, innovate, and lead fulfilling and purposeful lives. The relationship between being skillful and sustainable development is significant and multifaceted. Being skillful, both individually and collectively, contributes to the achievement of sustainable development goals in several ways. Skillful individuals and professionals are essential drivers of progress toward a more sustainable and environmentally responsible future. Their expertise and actions are critical in addressing the complex challenges that the world faces in achieving sustainability goals.</p>
                    </AboutThemeRight>
                </AboutTheme>

                <AboutKLU>
                <AboutKLURight>
                        <h1>About KL University</h1>
                        <p>A haven of knowledge and an ecstatic learning atmosphere, KL University is one of the most prestigious academic institutions in India with a supreme vision that it manifests in its exceptional operation.</p>
                        <p>Recognized as Deemed to be University in 2009, the foundation achieved immense acclaim for its incredible success in turning students into eminent leaders and industry experts.</p>
                        <p>Not only in the areas pertaining to academics here find an environment that helps them to pursue their passions and flourish in the fields of their interest. With a wide array of opportunities available at their disposal, the student community is engaged in continuously broadening their horizons.</p>
                        <a href="https://goo.gl/maps/8sGwAL2VB6fNCgrz6" target="_blank" rel="noreferrer"><i class="fa fa-map-marker"></i> KL University</a>
                    </AboutKLURight>
                    <AboutKLULeft>
                        <img src={image4} alt="4" />
                    </AboutKLULeft>
                    
                </AboutKLU>
                <NavBarSpace />
            </MainContainer>
            <SamyakFooter />
        </>
    );
};

export default SamyakAbout;